import React from 'react';
import { useReducer, createContext, useEffect } from "react";
import { projectAuth } from "../../firebase/config";
import { onAuthStateChanged } from 'firebase/auth';

export const AuthContext = createContext()

export const authReducer = (state, action) => {
    switch (action.type) {
        case 'LOGIN':
            console.log("login ")
            console.log({state})
            return {...state, user: action.payload }
        case 'LOGOUT':
            console.log("logout ")
            console.log({state})
            return {...state, user: null }
        case 'AUTH_IS_READY':
            return {...state, user: null, authIsReady: true }
        default:
            return state
    }
}

// To be used by index
export const AuthContextProvider = ( { children } ) => {
    const [state, dispatch] = useReducer(authReducer, {
        user: null,
        authIsReady: false
    })

    useEffect( () => {
        const unsub = onAuthStateChanged(projectAuth, (user) => {
            dispatch({ type: 'AUTH_IS_READY', payload: user })
            unsub()
        })
    }, [])

    return (
        <AuthContext.Provider value={ {...state, dispatch} }>
            { children }
        </AuthContext.Provider>
    )
}