import React from 'react';

import { useState } from 'react'
import styles from './Day.module.css'

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export default function Day( { day, startHour, endHour }) {
    // const [today, setToday] = useState(new Date())
    // const [startHour, setStartHour] = useState(6)
    // const [endHour, setEndHour] = useState(24)

    const getHourStyle = (hour) => {
        if (hour === day.getHours()) {
            return `${styles.day_hour} ${styles.present}`
        }
        else if (hour < day.getHours()) {
            return `${styles.day_hour} ${styles.past}`
        }
        else {
            return `${styles.day_hour} ${styles.future}`
        }
    }
    

    const generateHours = (from, to) => {
        if (from < 0) from = 0;
        if (to > 24) to = 24
        
        let hours = []
        for (let i = from; i < to; i++) {
            hours.push(
                <div key={i} className={getHourStyle(i)}>
                    <p className={styles['day_hour_label']}>{i}</p>
                    <div >

                    </div>
                </div>
            )
        }
                
        return hours
    }

  return (
    <div className={styles['day']}>
        <div className={styles['day_header']}>
            <h1 className={`${styles.day_date} center-text `}>
                Day is {dayNames[day.getDay()]}, {monthNames[day.getMonth()]} {day.getDate()}
            </h1>
        </div>
        <div className={`${styles.day_body_shape} ${styles.day_body_style}`} style={{gridTemplateRows : `repeat(${endHour-startHour}, auto)`}}>
            {generateHours(startHour, endHour)}
        </div>
    </div>
  )
}
