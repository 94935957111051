import React from 'react';

import { useState } from 'react'
import styles from './Today.module.css'
import Day from '../../components/day/Day';
import Planner from '../../components/planner/Planner';

export default function Today() {
    const [today, setToday] = useState(new Date())
    const [startHour, setStartHour] = useState(4)
    const [endHour, setEndHour] = useState(22)

  return (
    <div className={`center ${styles['container']}`}>
        <div className={`${styles.child}`}>
            <Day day={today} startHour={startHour} endHour={endHour}/>
        </div>
        <div className={`${styles.child}`}>
            <Planner startHour={startHour} endHour={endHour} />
        </div>
    </div>
  )
}
