import { initializeApp } from "firebase/app"
import { getFirestore} from 'firebase/firestore'
import { getAuth } from "firebase/auth"

const firebaseConfig = {
    apiKey: "AIzaSyDQbN5lQ53_891IfUPzDvjws4b_zQvnDys",
    authDomain: "daysplitter-652b1.firebaseapp.com",
    projectId: "daysplitter-652b1",
    storageBucket: "daysplitter-652b1.appspot.com",
    messagingSenderId: "1007510789979",
    appId: "1:1007510789979:web:91a903978961fa6f02847e",
    measurementId: "G-WEGJXNBCMF"
  }

    // init firebase
    initializeApp(firebaseConfig)

    // init services
    const projectFirestore = getFirestore()
    const projectAuth = getAuth()
  
    export { projectFirestore, projectAuth };