import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import React from 'react';


export default function Thankyou() {
    const navigate = useNavigate()

    useEffect((navigate) => {
        setTimeout(() => {
            navigate('/')
        }, 2000)
    }, [navigate])

  return (
    <h1 className=".center-text">Thank you!</h1>
  )
}
