import { AuthContext } from "../context/AuthContext/AuthContext"
import { useContext } from "react"

// Provide a way for components to access the context
export const useAuthContext = () => {
    const context = useContext(AuthContext)

    if (!context) {
        throw Error('useAuthContext must be inside an AuthContextProvider')
    }

    return context
}