
import React from 'react';

import styles from './Header.module.css'

export default function Header() {
  return (
    <div>
        <h1 className={`${styles.title} left-text`}>DaySplitter</h1>
    </div>
  )
}
