import React from 'react';
// import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

import styles from './Welcome.module.css'

import clockHand from '../../images/clockhands.png'

export default function Welcome( {user}) {
    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/today')
    }
    // useEffect(() => {
    //     setTimeout(() => {
    //         navigate('/today')
    //     }, 2000)
    // }, [navigate])

  return (
    <>
        <h1 className={`center-text ${styles.welcome_user}`}>Welcome {user}</h1>
        <img src={clockHand} alt='' className={`${styles.welcome_button_img}`}></img>
        <button className={`btn center ${styles.welcome_button} `} onClick={handleClick}>Clock In</button>
    </>
  )
}
