
import { useState } from 'react'

import { projectAuth } from '../firebase/config'
import { signOut } from 'firebase/auth'

import { useAuthContext } from './useAuthContex'

export const useLogout = () => {
    const [error, setError] = useState(null)
    const { dispatch } = useAuthContext()
  
    const logout = () => {
        signOut(projectAuth)
            .then(() => {
                console.log('user signed out')
                dispatch({type: 'LOGGED_OUT'})
            })
            .catch((err) => {
                console.log("Failed to log out")
                setError(err)
            })
    }

    

    return { logout, error }
}
