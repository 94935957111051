
import React from 'react'
import styles from '../day/Day.module.css'
import plannerstyles from './Planner.module.css'

export default function Planner( {startHour, endHour }) {

    return (
        <div className={styles['day']}>
            <div> </div>
            <div className={styles['day_body_shape']} style={{gridTemplateRows : `repeat(${endHour-startHour}, auto)`}}>
                <div className={plannerstyles['task']} style={{gridRow : `2 / 6`}}>Task</div>
            </div>
        </div>
    )
}
