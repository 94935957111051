import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/header/Header';
import React from 'react';

// Pages and Components
import Login from './pages/login/Login';
import Logout from './pages/logout/Logout';
import Signup from './pages/signup/Signup';
import { useAuthContext } from './hooks/useAuthContex';

// Styles
import './index.css'
import Thankyou from './pages/thankyou/Thankyou';
import Welcome from './pages/welcome/Welcome';
import Today from './pages/today/Today';


function App() {
  const { user } = useAuthContext()

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* {!user && <> */}
            {/* <Route path='/' element={<Navigate to="/login" />} /> 
            <Route path='/login' element={(
              <div className='middle'>
                <Header />
                <Login />
              </div>
            )} />
            {/* <Route path='/logout' element={<Logout />} />  */}
            <Route path='/logout' element={<Navigate to="/login" />} /> 
            <Route path='/signup' element={(
              <div className='middle'>
                  <Header />
                  <Signup />
                </div>
              )} /> */}
          {/* </>} */}
          {/* {user && <> */}
            <Route path='/' element={<Navigate to="/welcome" />} /> 
            <Route path='/login' element={<Navigate to="/welcome" />} /> 
            <Route path='/logout' element={<Logout />} /> 
            <Route path='/welcome' element={
                <div className='middle'>
                  {/* <Header /> */}
                  <Welcome user='Andy'/>
                </div>
              } />
            <Route path='/today' element={<Today/>} />
          {/* </>} */}

          <Route path='/thankyou' element={<div className='middle'><Thankyou /></div>} />
          <Route path='*' element={<Navigate to="/" />} />
         
        </Routes>
      </BrowserRouter>
      
    </div>
  )
}

export default App;
