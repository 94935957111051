import React from 'react';
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Styles
import styles from './Signup.module.css'

export default function Signup() {
    const [email, setEmail] = useState('')
    const navigate = useNavigate()

    const handleSubmit = (e) => {
        e.preventDefault()
        console.log({email})
        navigate('/thankyou')
    }

    return (
        <form onSubmit={handleSubmit} className={styles['signup-form']}>
            <p className='center-text'>Please join our waitlist</p>
            <label>
                <span><strong>Email:</strong></span>
                <input 
                    type="email"
                    onChange = {(e) => setEmail(e.target.value)}
                    value = {email}
                />
            </label>
            <div className={styles['signup-buttons']}>
                <button className='btn center'>Sign up</button>
            </div>
        </form>
    )
}
