
import React from 'react'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContex'
import { useLogout } from '../../hooks/useLogout'

export default function Logout() {
    const { logout, error } = useLogout()
    const navigate = useNavigate()

    const { user } = useAuthContext()

    // On load component, logout
    // useEffect(() => {
    //     if (!error) {
    //         logout()
    //     }
    // }, [error])

    const handleClick = () => {
        logout()
    }

    useEffect(() => {
    //     if (!user) {
    //         navigate("/login")
    //     }
        console.log("Loading logout page")
    }, [])

    return (
        <button onClick={handleClick}>Logout</button>
    )
}
